.custom-nav-bar {
  background-color: #fff;
  display: inline-flex;
  position: sticky;
  top: 60px; // height of header before it in the DOM
  z-index: 10;
  align-items: center;

  .dummy-div {
    height: 62px;
    width: 32px;
    background-color: #222222;
  }

  .custom-nav-btn {
    border: none;
    outline: none;
    padding: 10px 15px;
    font-size: 24px;
    border-right: 1px black solid;
  }

  .custom-btn-active {
    background-color: black;
    color: #2f9cc2;
  }

  .custom-btn-inactive {
    background-color: #444444;
    color: #dddddd;
  }

  .custom-btn-inactive:hover {
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #3498db;
  }
}
