.attribute-group-section {
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.list-group {
  max-height: 130px;
  overflow-y: auto;
}

.text-white-btn {
  color: white !important;
}
