@import "~bootstrap/scss/bootstrap";

html {
  font-size: 14px;
}

body {
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  color: #495057;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
  background-color: #c8e6c9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #ffcdd2;
  color: #c63737;
}

.customer-badge.status-negotiation {
  background-color: #feedaf;
  color: #8a5340;
}

.customer-badge.status-new {
  background-color: #b3e5fc;
  color: #23547b;
}

.customer-badge.status-renewal {
  background-color: #eccfff;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #ffd8b2;
  color: #805b36;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}

.product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}

.order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.order-badge.order-delivered {
  background: #c8e6c9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #ffcdd2;
  color: #c63737;
}

.order-badge.order-pending {
  background: #feedaf;
  color: #8a5340;
}

.order-badge.order-returned {
  background: #eccfff;
  color: #694382;
}

.image-text {
  margin-left: 0.5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: 0.125rem;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-multiselect-label-container {
  height: 30px;
}

.accordionContent {
  font-size: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  vertical-align: baseline;
  background: transparent;
  color: var(--eds-color-text-default);
  padding: 5px;
  margin: 4px;
  display: flex;
  justify-content: space-between;
}

.accordionContent:hover {
  background-color: lightgrey;
  cursor: pointer;
}

@font-face {
  font-family: "Nike TG";
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/Nike-TG.woff2) format("woff2");
}

@font-face {
  font-family: "Nike Futura";
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/Nike-Futura.woff2) format("woff2");
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row-space {
  padding: 4px;
}

.cols {
  margin: 24px 0 0 0;
}

.config-instance {
  width: 500px;
  font-size: 14px;
  line-height: 20px;
}

.right {
  text-align: right;
}

.top-border {
  border-top: 2px solid lightgray;
}

.left-border {
  border-left: 1px solid rgb(240, 239, 239);
}

.config-instance-detail {
  /* width: 800px; */
  font-size: 14px;
  line-height: 20px;
}

.icon {
  font-size: 4.6875rem;
  line-height: 100px;
  -webkit-text-stroke: 4px;
}

.nav-icon {
  -webkit-text-stroke: 1px;
}

.green {
  color: rgb(32, 170, 32);
}

.red {
  color: rgb(150, 14, 14);
}

.branch-name-text {
  font-size: 10px;
  color: transparent;
}

.top-12 {
  top: 12%;
}

.top-20 {
  top: 20%;
}

.top-50 {
  top: 50%;
}

.custom-card {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-width: 2px;
  border-style: solid;
  border-color: ghostwhite;
  border-radius: 3px;
  padding: 8px;
  margin: 8px;
}

.main-header {
  background-color: black;
  letter-spacing: 2px;
  position: sticky;
  top: 0;
  z-index: 10;
  // border-bottom: 4px solid #2f9cc2;

  .row {
    max-width: 100%;
    height: 60px;
    padding-top: 15px;

    .img {
      margin: 3px 0 0 56px;
      width: 50px;
      // padding-left: 56px;
      // height: 36px;
      font-size: 16px;
      color: #9e9ea0;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

      img {
        max-height: 20px;
      }
    }

    .text-header {
      font-family: Nike TG, Helvetica, sans-serif;
      text-transform: uppercase;
      margin: 0;
      color: white;
      font-size: 16px;
      padding: 3px 0 24px 0;
    }
  }
}

.main-table {
  width: -webkit-fill-available;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  .tab-header {
    font-family: "Nike TG";
    font-size: 16px;
    padding: 8px;
    background: #a19f9f;
  }

  .tab-row {
    padding: 20px 8px;
  }
}

.currsor-not {
  cursor: not-allowed !important;
  pointer-events: visible !important;
}

.form-container {
}

.container-fluid {
  min-width: 500px;
  // max-width: 850px;
  width: 100%;

  min-height: 300px;
  // max-height: 750px;
  height: 100%;
  overflow: hidden;

  padding: 0px 20px;
  background: #fff;
  margin: auto;

  .gray {
    background-color: #f6f6f6;
  }
}

.p-button {
  padding: 5px 15px 6px 15px;
  font-size: 1rem;
  color: #151515 !important;
  background-color: white;
  height: 32px;
  width: auto;
  border: 1px solid black;
  border-radius: 5px;
  float: left;
  margin: 0.25rem !important;

  &:enabled:hover {
    background: rgb(183, 181, 181);
    color: #ffffff;
    border-color: grey;
  }

  .p-button-secondary.p-button-outlined {
    color: #151515;
  }
}

.dropdown button {
  height: 31px;
}

.add-attribute-group-plus {
  display: block;
  float: right;
}
