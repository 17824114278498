.fill-out {
  display: block;
  overflow: hidden;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.fill-in {
  display: block;
  overflow: auto;
}

.right-side {
  // padding-top: 1rem;
  border-left: 1px solid #e6e6e6;
}

.full-screen-loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
}

.p-progress-spinner {
  width: 75px !important;
}

.p-progress-spinner-circle {
  stroke: #3498db !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* semi-transparent white background */
  z-index: 1001;
  /* Set a higher z-index than the loader */
}
