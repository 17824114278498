.attribute-group-div {
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .buttons {
    margin-bottom: -20px;
  }
}

.list-group {
  max-height: 130px;
  overflow-y: auto;
}
